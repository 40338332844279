import clsx from "clsx";
import { useEffect, useState } from "react";

type Route = {
  content?: string;
  image?: string;
  path: string;
};

const routes: Array<Route> = [
  {
    content: "o carte despre",
    path: "/",
  },
  {
    image: "/assets/images/logo.svg",
    path: "/",
  },
  {
    content: "cărțile mele",
    path: "/account?tab=books",
  },
  {
    content: "contul meu",
    path: "/account",
  },
  {
    content: "contact",
    path: "/contact",
  },
];

const routeIsActive = (path: string, currentRoute?: string) => {
  if (!currentRoute) {
    return false;
  }

  //TODO: refactor
  const wp = currentRoute;
  const rp = path;

  if (wp.indexOf("?") !== -1) {
    return (
      wp.split("?")[0] === rp.split("?")[0] &&
      wp.split("?")[1] === rp.split("?")[1] &&
      wp.split("?")[0].length > 0 &&
      wp.split("?")[1].length > 0
    );
  }

  return window.location.pathname === path;
};

const renderRoutes = (
  routes: Array<Route>,
  params?: { renderImages?: boolean; currentRoute?: string },
) =>
  routes.map((route: Route) => {
    if (route.content) {
      const routeClassName =
        "hover:drop-shadow text-2xl text-white" +
        (routeIsActive(route.path, params?.currentRoute) ? " font-bold" : "");

      return (
        <a key={route.content} className={routeClassName} href={route.path}>
          <span>{route.content}</span>
        </a>
      );
    }

    if (route.image && params?.renderImages !== false) {
      return (
        <a key={route.path} href={route.path}>
          <img className="h-10" src={route.image} />
        </a>
      );
    }

    return null;
  });

const logo = "/assets/images/logo.svg";

const Header = ({
  fixedHeader,
  filled,
}: {
  fixedHeader?: boolean;
  filled?: boolean;
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [currentRoute, setCurrentRoute] = useState<string>("");
  const [hasBackground, setHasBackground] = useState<boolean>(!!filled);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const _setHasBackground = (bg: boolean) => {
    if (filled) return;

    setHasBackground(bg);
  };

  useEffect(() => {
    setCurrentRoute(`${window.location.pathname}${window.location.search}`);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      _setHasBackground(window.scrollY > 50);
    };

    _setHasBackground(window.scrollY > 50);

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const bg =
    hasBackground || menuOpen || fixedHeader ? "bg-fgYellow" : "bg-transparent";
  const pos = fixedHeader ? "relative" : "sticky";

  return (
    <nav className={`w-full h-20 ${pos} top-0 left-0 z-50`}>
      <div className={`${bg} h-full visible lg:hidden`}>
        <div className="h-full px-12 flex justify-between items-center">
          <a href="/">
            <img className="h-12" src={logo} />
          </a>

          <div className="space-y-2" onClick={toggleMenu}>
            <div className="w-8 h-0.5 bg-fgLightYellow"></div>
            <div className="w-8 h-0.5 bg-fgLightYellow"></div>
            <div className="w-8 h-0.5 bg-fgLightYellow"></div>
          </div>
        </div>

        {menuOpen ? (
          <div
            className={`bg-fgYellow flex flex-col justify-center items-center gap-8 pt-4 pb-8 -mt-1`}
          >
            {renderRoutes(routes, { renderImages: false, currentRoute })}
          </div>
        ) : null}
      </div>

      <div className={`${bg} duration-300 hidden lg:flex h-full`}>
        <div className="flex-1 flex justify-end items-center pr-8">
          {/* Render the first route */}
          {renderRoutes([routes[0]])}
        </div>

        <div className="flex justify-center items-center">
          {/* Render the logo */}
          <a href={routes[1].path}>
            <img className="h-12" src={routes[1].image} />
          </a>
        </div>

        <div className="flex-1 flex justify-start items-center pl-8 gap-8">
          {/* Render the remaining routes */}
          {renderRoutes(routes.slice(2, 5), {
            renderImages: false,
            currentRoute,
          })}
        </div>
      </div>
    </nav>
  );
};

export default Header;
